
.App
{
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  padding: 50px 25px;

}


body,html
{
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}
[disabled]
{
 opacity: 0.5; 
}


.tailwind-remove ul { 
  list-style-type: disc; 
  list-style-position: inside; 
}
.tailwind-remove ol { 
  display: block !important;
    list-style-type: decimal !important;
    margin-block-start: 1em !important;
    margin-block-end: 1em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    padding-inline-start: 40px !important;
}
.tailwind-remove ul ul,.tailwind-remove ol ul { 
  list-style-type: circle; 
  list-style-position: inside; 
  margin-left: 15px; 
}
.tailwind-remove ol ol,.tailwind-remove ul ol { 
  list-style-type: lower-latin; 
  list-style-position: inside; 
  margin-left: 15px; 
}


.tailwind-remove ol,.tailwind-remove ul,.tailwind-remove menu {
  list-style: decimal;
  margin: 0;
  padding: 0;
}
.ql-align-center
{
  text-align: center;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {

  .card-blue-light {
    @apply  bg-fishes-blue-light h-8  rounded-md p-2 h-auto 
  }
  .btn
  {
    @apply rounded-md px-3 py-1 font-medium
  }

  .btn-primary
  {
    @apply bg-fishes-blue-dark border-fishes-blue-light  text-fishes-blue-light
  }

  .btn-red
  {

    @apply bg-red border-fishes-white  text-fishes-blue-light;
    background-color: red;
  }
  
  .btn-secondary
  {
    @apply bg-fishes-blue-disabled border-fishes-blue-dark text-fishes-blue-dark 
  }

  .btn-green
  {
    @apply bg-fishes-green-dark text-fishes-white
  }
  .btn-outline-green
  {
    @apply bg-none text-fishes-white border-2 border-fishes-green-dark text-fishes-green-dark
  }

  .disabled
  {
    @apply pointer-events-none opacity-70
  }
  .title-blue-dark
  {
    @apply text-lg font-bold  text-fishes-blue-dark
  }
  .input
  {
    @apply py-1.5 box-border px-4 rounded-md border border border-fishes-blue-dark shadow-sm
  }
  .input-sm
  {
    @apply py-1 box-border px-2 rounded-md border border border-fishes-blue-dark shadow-sm text-sm
  }
}

.select-special
{
  border: 3px solid red;
  /*background-color: #0e7000;*/
  /*  color: #ffffff;*/
}
.required{
  font-weight: bold;
  font-size: large;
  color: red;
}